import React from 'react';
import { FaTelegramPlane } from 'react-icons/fa';
const Newsletter = () => {
	return (
		<div className="newsletter_section_wrapper">
			<div className="container">
				<div className="newsletter_section">
					<div className="newsletter_info">
						<h5 className="newsletter_title">Subscribe Our Newsletter</h5>
						<form className="newsletter_subscription">
							<input className="add-email" type="email" placeholder="Enter your email address" />
							<button className="submit_email" type="button">
								Subscribe
							</button>
						</form>
						<p className="newsletter_subtitle">
							Let’s embody your beautiful ideas together, simplify the way you visualize your next big things.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Newsletter;
